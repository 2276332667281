<template>
	<div class="app-container body-tabs-shadow">
		<div class="app-container">
			<div class="row no-gutters h-screen">
				<div class="col-md-12 col-lg-6 col-xl-6 theme-side">
					<div class="p-4 flex flex-col space-y-5">
						<div class="mb-5">
							<div class="flex align-items-center w-full space-x-4 md:-ml-10 mt-5">
								<!-- <div> -->
								<img class="img-fluid md:h-auto logo-top md:pl-20 h-12 logo cursor-pointer" v-on:click="onNav"
									 src="./../../assets/images/logo/logo.png" alt="logo">
								<!-- </div> -->
								<!-- <div class="font-semibold text-lg md:text-2xl text-white md:tracking-wide whitespac-nowrap ">B2B Portal</div> -->
							</div>
							<div class="flex theme-header-size md:text-xl md:ml-10 text-white mt-4 login-description">
								{{ $t('welcomeEntry') }}
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-12 col-lg-6 col-xl-6">
					<div class="p-4 flex flex-col space-y-2">
						<div class="mb-5">
							<div class="flex md:justify-center align-items-center w-full space-x-4 md:-ml-10 mt-5">
								<div
									class="font-semibold md:ml-16  text-lg theme-header-size text-blue-900 md:tracking-wide">
									{{ $t('enterAccountInfo') }}
								</div>
							</div>
						</div>
						<div v-if="messageNotification.show === true" class="w-full lg:w-[80%] mx-auto">
							<p class="p-2 font-semibold rounded text-white"
							   :class="{'bg-green-500': messageNotification.status === true,'bg-red-500': messageNotification.status === false}">
								{{ $t(`${messageNotification.message}`) }}
							</p>
						</div>
						<div class="flex ">

							<div class="login-border-section w-full lg:w-[80%] mx-auto" v-loading="loading">
								<ValidationObserver ref="form" v-slot="{ invalid, handleSubmit }">
									<form class="mb-5" @submit.prevent="handleSubmit(processRegister)">

										<div class="form-group relative" v-for="(item,i) in formList" :key="i">
											<label :for="item.name" class="text-base font-semibold text-blue-900">{{
													$t(`${item.label}`)
												}}</label>
											<div>
												<ValidationProvider :name="item.name" :rules="item.rules"
																	v-slot="{ errors }">
													<span
														class="fa fa-eye position-absolute top-[45px] right-[10px] cursor-pointer"
														v-on:click.prevent="togglePassword(i,'password')"
														v-if="item.has_password === true && item.type === 'text'"></span>
													<span
														class="fa fa-eye-slash position-absolute top-[45px] right-[10px] cursor-pointer"
														v-on:click.prevent="togglePassword(i,'text')"
														v-if="item.has_password === true && item.type === 'password'"></span>
													<input v-model="item.value" :name="item.name" :type="item.type"
														   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
														   required>
													<small class="text-red-500 font-semibold">{{ errors[0] }}</small>
												</ValidationProvider>
											</div>
										</div>
										<div class="form-group">
											<!-- <label class="override-pb override-pt col-form-label label-context"></label> -->
											<div class="md:mt-20">
												<!-- <input v-model="confirmPassword" name="password" type="password" placeholder="Confirm Password" class="form-control override-form-field-l" required> -->
												<button class="w-full login-btn-success px-4 py-2 rounded-md"
														type="submit" :disabled="invalid || processing">
													<span class="text-white" v-if="processing">{{
															$t('creatingAccount')
														}}</span>
													<span class="text-white" v-else>{{ $t('createAccount') }}</span>
												</button>
											</div>
										</div>
									</form>
								</ValidationObserver>
							</div>
						</div>
					</div>
					<div class="container text-center md:bottom-0 md:absolute mb-4 flex justify-center">
						<span class="text-blue-900">Copyright © <span>{{ current }} B2B Portal</span></span>
						<span>
							<locale-component :show-text="true" :height="5" :width="5"/>
                        </span>
					</div>
				</div>
			</div>
		</div>
		<Notification/>
	</div>
</template>

<script>
import {mapGetters} from 'vuex'
import {ValidationProvider, ValidationObserver, localeChanged} from 'vee-validate'
import LocaleComponent from "../../components/LocaleComponent";

export default {
	name: "SellerSignup",
	components: {
		Notification: () => import('./../../customComponent/NotificationAlert'),
		ValidationProvider,
		ValidationObserver,
		LocaleComponent
	},
	data() {
		return {
			current: new Date().getFullYear(),
			baseURL: window.location.origin,
			loaded: false,
			username: '',
			email: '',
			first_name: '',
			domain: '',
			password: '',
			confirmPassword: '',
			processing: false,
			loading: true,
			accessToken: '',
			formList: [
				{
					label: 'fields.firstName',
					name: 'first_name',
					value: '',
					type: 'text',
					rules: 'required',
					has_password: false
				},
				{
					label: 'fields.lastName',
					name: 'last_name',
					value: '',
					type: 'text',
					rules: 'required',
					has_password: false
				},
				{
					label: 'fields.email',
					name: 'email',
					value: '',
					type: 'email',
					rules: 'required|email',
					has_password: false
				},
				{
					label: 'fields.phone_number',
					name: 'phone_number',
					value: '',
					type: 'text',
					rules: 'required',
					has_password: false
				},
				{
					label: 'fields.companyName',
					name: 'name',
					value: '',
					type: 'text',
					rules: 'required',
					has_password: false
				},
				{
					label: 'organizationNumber',
					name: 'organization_number',
					value: '',
					type: 'text',
					rules: 'required',
					has_password: false
				},
				{
					label: 'fields.password',
					value: '',
					name: 'password',
					type: 'password',
					rules: 'required|minmax:7,15',
					has_password: true
				},
				{
					label: 'fields.confirmNewPassword',
					value: '',
					name: 'confirm_password',
					type: 'password',
					rules: 'required|minmax:7,15',
					has_password: true
				},
			],
			subscription: {},
			messageNotification: {
				show: false,
				status: false,
				message: 'Your account has been created successfully'
			}
		}
	},
	computed: {
		...mapGetters({
			// USER_TOKEN : 'USER_TOKEN',
			// GET_USER_UTIL : 'GET_USER_UTIL',
			GET_LOCALE_LANG: 'utility/GET_LOCALE_LANG'
		})
	},
	methods: {
		changeLocale(locale) {
			i18n.locale = locale
			this.$store.commit('utility/CHANGE_LOCALE', locale)
			localeChanged()
		},
		processRegister() {
			let payload = {}
			for (let i = 0; i < this.formList.length; i++) {
				let item = this.formList[i]
				if (!item.value) {
					this.$services.helpers.notification(`${this.$t(`${item.label}`)} ${this.$t('fieldRequired')}`, 'error', this)
					return false
				}
				if (item.type === 'email') {
					if (!this.$services.helpers.validateEmailAddress(item.value)) {
						this.$services.helpers.notification(this.$t('invalidEmail'), 'error', this)
					}
				}
				if (['password', 'email'].includes(item.type)) {
					if (item.value.length > 50) {
						this.$services.helpers.notification(this.$t('fieldValue'), 'error', this)
						return false
					}
				}
				if (item.has_password) {
					if (item.value.length < 6) {
						this.$services.helpers.notification(this.$t('passwordGreater'), 'error', this)
						return false
					}
				}
				payload[item.name] = item.value
			}
			if (!this.subscription['id']) {
				this.showNotification('subscriptionNotSelectedByCustomer', false, true)
				return false
			}
			let password = this.formList.filter(item => item.has_password === true && item.name === 'password')
			let confirmPassword = this.formList.filter(item => item.has_password === true && item.name === 'confirm_password')
			if (password.length <= 0 || confirmPassword.length <= 0) {
				this.$services.helpers.notification(this.$t('passwordNotMatch'), 'error', this)
				return false
			}
			if (password[0].value !== confirmPassword[0].value) {
				this.$services.helpers.notification(this.$t('passwordNotMatch'), 'error', this)
				return false
			}

			this.processing = true
			this.$store.dispatch('auth/registerSellerAccount', payload)
				.then(res => {
					this.createSubscription(this.subscription.id, res.data.company.id)
				})
				.catch(err => {
					this.processing = false
					if (err.response.status === 400) {
						if (err.response.data) {
							this.$services.helpers.notification(this.formatErrorMessage(err.response.data), 'error', this)
						}
					}
					if (err.response.status === 500) {
						this.$services.helpers.notification(this.$t('serverError'), 'error', this)
					}
				})
		},
		createSubscription(subscription_id, companyId) {
			this.$store.dispatch('portal/addUserSubscription', {
				supplier_subscription_type_id: parseInt(subscription_id),
				company_id: companyId
			})
				.then(_ => {
					// this.$services.helpers.notification(this.$t('subscriptionSuccessMessage'), 'success', this)
					this.showNotification('subscriptionSuccessMessage', true, true)
					setTimeout(() => {
						this.$router.replace({name: 'login'})
					}, 1500)
				})
				.catch(err => {
					this.processing = false
					// this.showNotification('subscriptionSuccessMessage', true, true)
					this.$services.helpers.notification(err.response.data.message, 'error', this)
				})
		},
		validateCompanyNumber(number) {
			if (!number.includes('-')) {
				return true
			}
			if (number.split('-')[0].length === 6 && number.split('-')[1].length === 4) {
				return false
			}
			return true
		},
		formatErrorMessage(data = {}) {
			if ('user' in data) {
				const user = data.user
				if ('email' in user) {
					return user.email.join('')
				} else {
					return this.$t('invalidCredential')
				}
			} else if ('errors' in data) {
				for (const [key, value] of Object.entries(data['errors'])) {
					return value
				}
			} else {
				return this.$t('invalidCredential')
			}
		},
		togglePassword: function (index, type) {
			this.formList[index].type = type
		},
		getSubscriptionType: function (subscriptionName, subscriptionType) {
			this.loading = true
			this.$store.dispatch('auth/getSubscriptionType', {data: `slug=${subscriptionName}&type=${subscriptionType}`}).then(resp => {
				this.loading = false
				if (resp.data.data.length <= 0) {
					this.messageNotification.message = 'subscriptionNotSelectedByCustomer'
					this.messageNotification.status = false
					this.messageNotification.show = true
					return false
				}
				this.subscription = resp.data.data[0]
			}).catch(err => {
				this.loading = false
				this.messageNotification.message = 'subscriptionNotSelectedByCustomer'
				this.messageNotification.status = false
				this.messageNotification.show = true
			})
			this.closeNotification()

		},
		closeNotification: function () {
			let self = this
			setTimeout(function () {
				self.messageNotification = {
					show: false,
					status: false,
					message: ''
				}
			}, 6000)

		},
		showNotification: function (message, status, show) {
			this.messageNotification.message = message
			this.messageNotification.status = status
			this.messageNotification.show = show
			this.closeNotification()
		},
		onNav: function () {
			this.$router.push({'name': 'login'})
		}
	}
	,
	mounted() {
		this.getSubscriptionType(this.$route.query.package, this.$route.query.solution)
	}
}
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";

#app {
	overflow-y: hidden !important;
}

.login-head-text {
	font-size: 1.2rem !important;
}

.login-head-text-xl {
	font-size: 1.5rem !important;
}

.override-form-field {
	border: 0;
	border-bottom: 1px solid rgb(64, 138, 235);
	box-shadow: none;
	background-color: unset !important;
	border-radius: unset !important;
}

// .btn-custom {
//     background-color: #F27E44 !important;
//     border-color: #F27E44 !important;
//     color: #FFFFFF;
// }
.override-pt {
	padding-top: 1.2rem !important;
}

.override-pb {
	padding-bottom: unset !important;
}

.overide-width {
	width: 50% !important;
}

.font-md-login {
	font-size: 1rem;
}

.form-group {
	margin-bottom: 0.5rem !important;
}

.form-control {
	// border: 1px solid $form-border !important;
	height: unset !important;
	padding: 0.2rem !important;
}

.form-control:focus {
	box-shadow: unset !important;
	// border: 1px solid $theme-primary-color !important;
}

.theme-side {
	background: $production-theme;
}

.login-btn-success {
	background: #E8B105;
}

.logo {
	height: 95px;
}

.login-description {
	position: relative;
	top: 177px;
}

@media only screen and (max-width: 600px) {
	.logo {
		height: 48px;
	}
	.login-description {
		top: 0px
	}
}
</style>
