<template>
    <div class="app-container app-theme-white body-tabs-shadow">
        <div class="app-container dm-sans-font">
            <div class="row no-gutters h-screen">
                <div class="col-md-12 col-lg-6 col-xl-6">
                    <div class="p-4 flex flex-col space-y-5">
                        <div class="md:mb-32">
                            <div class="flex md:justify-center align-items-center w-full space-x-4 md:-ml-10 mt-5">
                                <!-- <div> -->
                                    <img class="img-fluid md:h-12 logo-top md:pl-5" src="./../../assets/images/logo/old-web.png" alt="logo">
                                <!-- </div> -->
                                <div class="theme-main-size font-semibold text-lg md:text-2xl text-blue-900 md:tracking-wide whitespac-nowrap ">{{ $t('onboardHeader') }}</div>
                            </div>
                            <div class="flex justify-center mt-5 text-lg md:text-xl ml-2 theme-header-size theme-color">
                                {{ $t('serviceStart') }}?
                            </div>
                        </div>
                        <div class="md:mt-20">
                            <div class="no-gutters row flex justify-center mt-20 mb-5" v-if="loading">
                                <svg class="animate-spin h-8 w-8 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            </div>
                            <div class="flex justify-center mt-5" v-else>
                                <button @click="getPermissionUrl" class="bg-blue-900 px-3 py-2 rounded shadow-sm text-xl md:w-80">
                                    <div class="flex justify-center items-center m-auto" v-if="processing">
                                        <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        <span class="text-white">{{ $t('connecting') }}</span>
                                    </div>
                                    <span class="m-auto text-white" v-else>{{ $t('connectNow') }}</span>
                                </button>
                            </div>
                            <div class="flex justify-center mt-3" v-if="error">
                                <span class="text-red-500">{{ errorMessage }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-lg-6 col-xl-6 theme-side">
                    <div class="p-4 flex flex-col space-y-5">
                        <div class="md:mb-32">
                            <div class="flex justify-center align-items-center w-full space-x-4 mt-5">
                                <div class="font-semibold text-lg md:text-2xl text-white md:tracking-wide theme-header-size">{{ $t('welcomeEntry') }}</div>
                            </div>
                        </div>
                        <div class="md:mt-20 w-full">
                            <div class="flex flex-col justify-center md:mt-24 mb-10">
                                <div class="row">
                                    <div class="col-md-3"></div>
                                    <div class="col-md-9">
                                        <div class="mb-3 flex justify-center md:justify-start text-white font-semibold">{{ $t('customerAlready') }}?</div>
                                        <div class="w-full flex justify-center md:justify-start">
                                            <button @click="$router.push({ name : 'login' })" class="bg-yellow-600 px-3 py-2 rounded shadow-sm text-xl md:w-80">
                                                <span class="m-auto text-white">{{ $t('customerPortal') }}</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="md:mt-20">
                            <div class="flex justify-center md:mt-20">
                                <button @click="getPermissionUrl" class="bg-blue-900 px-3 py-2 rounded shadow-sm text-xl md:w-1/2">
                                    <div class="flex justify-center items-center m-auto" v-if="processing">
                                        <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        <span class="text-white">{{ $t('connecting') }}</span>
                                    </div>
                                    <span class="m-auto text-white" v-else>{{ $t('connectNow') }}</span>
                                </button>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <!-- <Notification /> -->
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name : 'entry',
        components : {
            // Notification : () => import('./../../customComponent/NotificationAlert')
        },
        data () {
            return {
                current : new Date().getFullYear(),
                baseURL : window.location.origin,
                loading : true,
                finalDisplay : false,
                showFirstPage : false,
                companyName : '',
                errorMessage : '',
                checking : true,
                success : false,
                error : false,
                processing : false,
                resending : false
            }
        },
        computed : {
            ...mapGetters({
                USER_TOKEN : 'auth/USER_TOKEN',
                // GET_USER_UTIL : 'auth/GET_USER_UTIL'
            })
        },
        mounted () {
            const { code, state } = this.$route.query
            const entryAuth = window.localStorage.getItem('__entryAuth__')

            if ((code && state) && !entryAuth) {
                this.loading = true
                if (state.startsWith('LOGGED-IN')) {
                    this.initializeFortnoxIntegrationBuyer(code, state)
                } else {
                    this.initializeToken(code)
                }
            } else {
                this.loading = false
                if (entryAuth) {
                    this.finalDisplay = true
                    this.$router.push({ name : 'confirm' })
                } else {
                    this.finalDisplay = false
                    window.localStorage.removeItem('__entryAuth__')
                }
            }
        },
        methods : {
            getPermissionUrl () {
                this.processing = true
                this.$store.dispatch('auth/getPermissionUrl')
                .then(res => {
                    this.processing = false
                    window.open(res.data.url, '_self')
                })
                .catch(_ => {
                    this.processing = false
                })
            },
            initializeToken (data) {
                const payload = {
                    query : `?auth_code=${data}`
                }
                this.$store.dispatch('auth/getAccessToken', payload)
                .then(response => {
                    this.error = false
                    this.loading = false
                    this.finalDisplay = true
                    window.localStorage.setItem('__entryAuth__', JSON.stringify(response.data))
                    this.$router.push({ name : 'confirm' })
                })
                .catch(err => {
                    this.loading = false
                    this.success = false
                    this.finalDisplay = false
                    if (err.response.status === 400) {
                        this.error = true
                        this.errorMessage = err.response.data.error
                    }
                    if (err.response.status === 500) {
                        this.error = true
                    }
                })
            },
            initializeFortnoxIntegrationBuyer (code, state) {
                const payload = {
                    query : `?auth_code=${code}&state=${state}`
                }
                this.$store.dispatch('customer/fortnoxAddition', payload)
                .then(response => {
                    this.$router.replace({ name : 'addfortnox', query : { integration : response.data.integration_id } })
                })
                .catch(err => {
                    this.loading = false
                    this.success = false
                    this.finalDisplay = false
                    if (err.response.status === 400) {
                        setTimeout(() => {
                            this.error = true
                            this.errorMessage = err.response.data.error
                        }, 1000)
                    }
                    if (err.response.status === 500) {
                        this.error = true
                    }
                })
            },
            connectCompany () {
                this.processing = true
                setTimeout(() => {
                    this.$router.push({ name : 'register' })
                }, 2000)
            },
        }
    }
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";
#app{
    overflow-y: hidden !important;
}
.login-head-text{
    font-size: 1.2rem !important;
}
.login-head-text-xl{
    font-size: 1.5rem !important;
}
.override-form-field{
    border: 0;
    border-bottom: 1px solid rgb(64, 138, 235);
    box-shadow: none;
    background-color: unset !important;
    border-radius: unset !important;
}

.override-pt{
    padding-top: 1.2rem !important;
}
.override-pb{
    padding-bottom: unset !important;
}
.custom-rem{
    padding: 5rem;
}
.theme-side {
    background: $production-theme;
}
.theme-color {
    color : $production-theme;
}
</style>
